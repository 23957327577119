import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { Fragment, useCallback } from "react";
import { useBanner } from "../../contexts";
import { BannerButton, BannerDevHint } from ".";
import { getOtherOptionsFromWindow } from "../../utils";

var BannerButtons = function BannerButtons() {
  var banner = useBanner();
  var activeAction = banner.activeAction,
      decision = banner.decision,
      _banner$texts = banner.texts,
      acceptAll = _banner$texts.acceptAll,
      acceptEssentials = _banner$texts.acceptEssentials,
      acceptIndividual = _banner$texts.acceptIndividual,
      groups = banner.groups,
      essentialGroup = banner.essentialGroup,
      saveButton = banner.saveButton,
      individualTexts = banner.individualTexts,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      updateIndividualPrivacyOpen = banner.updateIndividualPrivacyOpen,
      didGroupFirstChange = banner.didGroupFirstChange,
      updateGroupChecked = banner.updateGroupChecked,
      updateCookieChecked = banner.updateCookieChecked,
      onSave = banner.onSave,
      _banner$buttonClicked = banner.buttonClicked,
      buttonClicked = _banner$buttonClicked === void 0 ? "" : _banner$buttonClicked;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro;

  var handleClickIndividualPrivacy = useCallback(function () {
    updateIndividualPrivacyOpen(true);
  }, [updateIndividualPrivacyOpen]);
  var buttonClickedAll = individualPrivacyOpen ? "ind_all" : "main_all";
  var buttonClickedEssentials = individualPrivacyOpen ? "ind_essential" : "main_essential";
  var buttonClickedCustom = individualPrivacyOpen ? "ind_custom" : "main_custom";
  var handleAll = useCallback(function () {
    groups.forEach(function (g) {
      return updateGroupChecked(g.id, true);
    });
    onSave(false, buttonClickedAll);
  }, [buttonClickedAll]);
  /**
   * This includes the complete "Essential" group + legal basis = legitimate interests.
   */

  var handleEssentials = useCallback(function () {
    groups.forEach(function (_ref) {
      var slug = _ref.slug,
          id = _ref.id,
          items = _ref.items;

      if (slug === essentialGroup) {
        updateGroupChecked(id, true);
      } else {
        var _iterator = _createForOfIteratorHelper(items),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _step$value = _step.value,
                legalBasis = _step$value.legalBasis,
                cookieId = _step$value.id;

            if (legalBasis === "legitimate-interest") {
              updateCookieChecked(id, cookieId, true);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    });
    onSave(false, buttonClickedEssentials);
  }, [buttonClickedEssentials]);
  return h(Fragment, null, !individualPrivacyOpen && isPro && decision.groupsFirstView && decision.saveButton === "afterChangeAll" && didGroupFirstChange ? h(BannerButton, {
    onClick: function onClick() {
      return onSave(false, buttonClickedCustom);
    },
    type: decision.acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedCustom
  }, individualTexts.save) : h(BannerButton, {
    onClick: handleAll,
    type: decision.acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedAll
  }, acceptAll), activeAction !== "change" && h(BannerButton, {
    onClick: handleEssentials,
    type: decision.acceptEssentials,
    inlineStyle: "acceptEssentials",
    framed: buttonClicked === buttonClickedEssentials
  }, acceptEssentials), (individualPrivacyOpen || isPro && decision.groupsFirstView && (decision.saveButton === "always" || decision.saveButton === "afterChange" && didGroupFirstChange)) && h(BannerButton, {
    onClick: function onClick() {
      return onSave(false, buttonClickedCustom);
    },
    type: saveButton.type,
    inlineStyle: "save",
    framed: buttonClicked === buttonClickedCustom
  }, individualTexts.save), !individualPrivacyOpen && h(BannerButton, {
    type: decision.acceptIndividual,
    onClick: handleClickIndividualPrivacy,
    inlineStyle: "acceptIndividual",
    framed: buttonClicked.startsWith("ind_")
  }, acceptIndividual), h(BannerDevHint, null));
};

export { BannerButtons };