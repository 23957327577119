import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { useState } from "react";
import { bannerFooterInlineStyleLink } from "./inlineStyles";
import { useBanner } from "../../contexts";

var BannerFooterLink = function BannerFooterLink(_ref) {
  var children = _ref.children,
      alignRight = _ref.alignRight,
      anchorRest = _objectWithoutProperties(_ref, ["children", "alignRight"]);

  var banner = useBanner();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  return h("a", _extends({
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }, bannerFooterInlineStyleLink(banner, alignRight, isHover), anchorRest), children);
};

export { BannerFooterLink };